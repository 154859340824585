import i18n from '@/plugins/localization/index'

export const qualDocsHeaders = [
  { value: 'sailor.id', text: i18n.t('sailorId'), sortable: false },
  { value: 'number_document', text: i18n.t('number'), sortable: false },
  { value: 'type_document', text: i18n.t('typeDoc'), sortable: false },
  { value: 'sailor.full_name_ukr', text: i18n.t('fullName'), sortable: false },
  { value: 'sailor.birth_date', text: i18n.t('dateBorn'), sortable: false },
  { value: 'country', text: i18n.t('country'), sortable: false },
  { value: 'rank', text: i18n.t('rank'), sortable: false },
  { value: 'position', text: i18n.t('position'), sortable: false },
  { value: 'status_document', text: i18n.t('status'), sortable: false },
  { value: 'event', text: i18n.t('actions'), sortable: false }
]

export const statementQualHeaders = [
  { value: 'sailor.id', text: i18n.t('sailorId'), sortable: false },
  { value: 'number', text: i18n.t('number'), sortable: false },
  { value: 'type_document', text: i18n.t('typeDoc'), sortable: false },
  { value: 'sailor.full_name_ukr', text: i18n.t('fullName'), sortable: false },
  { value: 'sailor.birth_date', text: i18n.t('dateBorn'), sortable: false },
  { value: 'is_payed', text: i18n.t('isPayed'), sortable: false },
  { value: 'rank', text: i18n.t('rank'), sortable: false },
  { value: 'list_positions', text: i18n.t('position'), sortable: false },
  { value: 'status_document', text: i18n.t('status'), sortable: false },
  { value: 'event', text: i18n.t('actions'), sortable: false }
]
