<template lang="pug">
  div
    include ../../../configs/template
    div.mt-7.ml-7
      +radio-group('typeDocument','typeDocumentList','typeDoc')(row)
    TableV2(
      :headers="headers"
      :items="isLoading ? [] : items.results"
      :pageCount="items.count"
      :currentPage="items.current"
      :querySearch="getDataReport"
      :isLoading="isLoading"
      :actions="actions"
      :saveReport="downloadReportExcel")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { statementQualHeaders } from './constants'

export default {
  name: 'DocListQualReport',
  data () {
    return {
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.go',
          action: (item) => this.goToDocumentSailor(item),
          color: 'blue',
          name: 'mdi-arrow-right',
          isView: true
        }
      ],
      headers: statementQualHeaders,
      typeDocument: 'statementQualification',
      typeDocumentList: [
        { label: this.$t('qualificationDocs'), value: 'diplomasQualification' },
        { label: this.$t('qualificationStatement'), value: 'statementQualification' }
      ],
      items: [],
      tableLoader: false,
      sortAcs: null,
      sortDesc: null,
      sortName: null,
      typeDoc: null
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.report.isLoading
    })
  },
  watch: {
    typeDocument (newVal, oldVal) {
      if (newVal !== oldVal) this.$router.push('/report/qualification')
    }
  },
  methods: {
    ...mapActions(['getReportStatementQualification', 'getReportStatementQualificationExcel']),
    goToDocumentSailor (item) {
      item && this.$router.push({ path: `/sailor/${item.sailor.id}/qualification/statements/${item.id}` })
    },
    async getDataReport (filter) {
      this.items = await this.getReportStatementQualification(filter)
    },
    async downloadReportExcel (params) {
      await this.getReportStatementQualificationExcel(params)
    }
  }
}
</script>
